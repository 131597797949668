header.main-nav.main-hover{
  position: fixed;
  z-index: 9999;
  top: 0;
  background-color: transparent;
  -webkit-transition: top 1s;
  transition: top 1s;
  
  &.open-mobile{
    @media (max-width: 760px) {
      background-color: white;
    
    }
  }

  &.fix,
  &.scroll {
    background-color: white;
  }

  &.scroll {
    position: fixed !important;
  }

  ul{

    > li {

      margin: 0px 15px;
      
    }

  }
}

.dropdown-menu{
  min-width: 14rem !important;

  ul {
    li{
      
      a{
        transition: all 0.5s;
      }

      a:hover{
        color: #0F89DD !important;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

}

#main-menu{

  @media (min-width: 760px) {
    
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.30);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.30);

    .dropdown-toggle::after{
      display: none;
    }
  }

  @media (max-width: 760px) {
    .dropdown-toggle::after{
      float: right;
      margin-top: 12px;
    }

    #options-menu{
      background-color: white;
      position: absolute;
      z-index: 999;
      top: 0;
      padding-top: 20px;
    }


    .dropdown .dropdown-toggle{
      display: block;
    }

    .dropdown .dropdown-toggle[aria-expanded=true] + .dropdown-menu{
      position: relative !important;
      transform: none !important;
    }
  }

  &.fix{
    position: relative;
    .big-logo{
      display: none;
    }
  }

  &.scroll{
    margin-top: 0 !important;

    .big-logo{
      display: none;
    }

    .simple-logo{
      display: inline-block !important;
    }
  }

  &:not(.fix){
    .simple-logo{
      display: none;
    }
  }
}


@media (max-width: 760px) {
  .big-logo{
    display: none;
  }

  #main-menu{
    position: relative;
    
    .simple-logo{
      display: block !important;
      height: 50px;
      float: right;
    }
  }
}

.dropdown.show > a{
  border-bottom: solid 1px gray;
}

.d-menu-mobile{
  position: absolute;
  top: 0;
  background: white;
  padding: 5px 20px;
}

.dropdown-sub-menu{
  position: relative;

  @media (min-width: 760px) {
    &::after{
      content: '\f054';
      font-family: "font-awesome-5"; 
      color: #8a8787;
      font-style: normal;
      float: right;
    }
  }

  .sub-menu{
    position: absolute;
    display: none;
    right: 0;
    top: 0;
    background: #EDEDED;
    border-left: solid 5px white;

    ul {
      flex-wrap: wrap;
      height: 100%;

      li{
        flex: 1;
        background: #EDEDED;
        margin: 0 !important;
        
        a{
          display: block;
          border-bottom: solid 1px #75777A;
          text-decoration: none;
          padding: 7px 15px;
          margin: 0 5px;
          min-width: 150px;
        }
      }
    }
  } 

  &:hover .sub-menu{
    display: block;
  }
}