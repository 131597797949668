.ctrl {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #D5DCE6;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.ctrl__counter {
  position: relative;
  width: 150px;
  height: 32px;
  color: #333C48;
  text-align: center;
  overflow: hidden;
}

.ctrl__counter.is-input .ctrl__counter-num {
  visability: hidden;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

.ctrl__counter.is-input .ctrl__counter-input {
  visability: visible;
  opacity: 1;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

.ctrl__counter-input {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
  box-shadow: none;
  outline: none;
  border: none;
  color: #333C48;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  visability: hidden;
  opacity: 0;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

.ctrl__counter-num {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 30px;
  visability: visible;
  opacity: 1;
  border: solid 1px #8498a7;
  border-radius: 5px;
  margin: 0 1px;
  -webkit-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
}

.ctrl__counter-num.is-increment-hide {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-animation: increment-prev 100ms ease-in;
  animation: increment-prev 100ms ease-in;
}

.ctrl__counter-num.is-increment-visible {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: increment-next 100ms ease-out;
  animation: increment-next 100ms ease-out;
}

.ctrl__counter-num.is-decrement-hide {
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-animation: decrement-prev 100ms ease-in;
  animation: decrement-prev 100ms ease-in;
}

.ctrl__counter-num.is-decrement-visible {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: decrement-next 100ms ease-out;
  animation: decrement-next 100ms ease-out;
}

.ctrl__button {
  width: 100px;
  line-height: 30px;
  text-align: center;
  color: #8498a7;
  border: solid 1px #8498a7;
  cursor: pointer;
  background-color: #fff;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
}

.ctrl__button:hover {
  background-color: #90a2b0;
  color: white;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
}

.ctrl__button:active {
  background-color: #778996;
  -webkit-transition: background-color 100ms ease-in;
  transition: background-color 100ms ease-in;
}

// .ctrl__button--decrement { border-radius: 5px 0 0 5px; }
// .ctrl__button--increment { border-radius: 0 5px 5px 0; }
.ctrl__button--decrement { border-radius: 5px; }
.ctrl__button--increment { border-radius: 5px; }

@-webkit-keyframes 
decrement-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
 @keyframes 
decrement-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@-webkit-keyframes 
decrement-next {  from {
 opacity: 0;
 -webkit-transform: translateY(-50px);
 transform: translateY(-50px);
}
}
@keyframes 
decrement-next {  from {
 opacity: 0;
 -webkit-transform: translateY(-50px);
 transform: translateY(-50px);
}
}
@-webkit-keyframes 
increment-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@keyframes 
increment-prev {  from {
 opacity: 1;
 -webkit-transform: translateY(0);
 transform: translateY(0);
}
}
@-webkit-keyframes 
increment-next {  from {
 opacity: 0;
 -webkit-transform: translateY(50px);
 transform: translateY(50px);
}
}
@keyframes 
increment-next {  from {
 opacity: 0;
 -webkit-transform: translateY(50px);
 transform: translateY(50px);
}
}