body{
  overflow-x: hidden;
  margin: 0;
}

p, h1, h2, h3, h4, h5, a, li{
  color: inherit;
  font-family: inherit;
}

a{
  color: inherit;
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}

// *title size*
h1{ font-size: 30px; }
h2{ font-size: 23px; }
h3{ font-size: 22px; }
h4{ font-size: 20px; }
h5{ font-size: 18px; }

// *Colours definition*
$colors:
  "none" transparent,
  "yellow" #FFD20C,
  "beige" #FFF9E9,
  "black" #1D1D1B,
  "dark" #3C3C3B,
  "white" #FFFFFF,
  "white-o" #F6F6F6,
  "blue" #0F89DD,
  "blue-o" #00296D,
  "blue-c" #8FC7EE,
  "blue-m" #17A9E6,
  "brown" #F1B97A,
  "green" #02d425,
  "gray" #75777A,
  "gray-o" #EDEDED,
  "red" #d9534f;

@function setColor($color) {
  $result: white;
  @each $i in $colors{
    @if nth($i, 1) == $color {
      $result: nth($i, 2);
    }
  }
  @return $result;
}

// * Fonts size definition *
$fontSize:
  "ss" 10px,
  "xs" 14px,
  "sm" 16px,
  "md" 18px,
  "xl" 20px,
  "lg" 24px,
  "gd" 26px,
  "mg" 28px,
  "bg" 34px,
  "xg" 38px;

@each $i in $colors{

  // font colors 
  .font-hover-#{nth($i, 1)}:hover,
  .font-#{nth($i, 1)} {
    color:nth($i, 2) !important;
  }

  .font-hover-link-#{nth($i, 1)}:hover{
    color:nth($i, 2) !important;
    text-decoration: underline !important;
  }

  // background colors
  .bg-hover-#{nth($i, 1)}:hover,
  .bg-#{nth($i, 1)} {
    background-color: nth($i, 2) !important;
  }

  // border colors
  .border-#{nth($i, 1)} {
    border-color:nth($i, 2) !important;
  }
  
  // border colors
  .border-solid-#{nth($i, 1)} {
    border: solid 1px nth($i, 2) !important;
  }
  
  .border-hover-solid-#{nth($i, 1)}:hover {
    border: solid 1px nth($i, 2) !important;
  }
  
  .border-solid2-#{nth($i, 1)} {
    border: solid 2px nth($i, 2) !important;
  }

  .border-solid-bottom-#{nth($i, 1)} {
    border-bottom: solid 1px nth($i, 2) !important;
  }

  .border-solid2-bottom-#{nth($i, 1)} {
    border-bottom: solid 2px nth($i, 2) !important;
  }
  
  .border-solid-top-#{nth($i, 1)} {
    border-top: solid 1px nth($i, 2) !important;
  }
  
  .border-dashed-bottom-#{nth($i, 1)} {
    border-bottom: dashed 1px nth($i, 2) !important;
  }
  
}

// font size
@each $i in $fontSize{
  
  .fs-#{nth($i, 1)}{
    font-size: nth($i, 2) !important;
  }

}

@media (min-width: 768px){
  
  // colores
  @each $i in $colors{
    
    .border-md-solid-bottom-#{nth($i, 1)} {
      border-bottom: solid 1px nth($i, 2) !important;
    }

  }
  
  // font size
  @each $i in $fontSize{
    
    .fs-md-#{nth($i, 1)}{
      font-size: nth($i, 2) !important;
    }

  }
}

// small width / height
@for $i from 9 to 1 {

  // *width*
  .w-#{($i * 5)}px{ 
    width: (($i * 5) * 1px) !important;
  }

  // *height*
  .h-#{($i * 5)}px{ 
    height: (($i * 5) * 1px) !important;
  }
  
}

@for $i from 13 to 0 {
  
  // *Rotate class*
  .rotate-#{($i * 30)}{ 
    transform: rotate(($i * 30) * 1deg) !important;
  }

  // *width*
  .w-#{($i * 50)}px{ 
    width: (($i * 50) * 1px) !important;
  }

  // *height*
  .h-#{($i * 50)}px{ 
    height: (($i * 50) * 1px) !important;
  }

  // *max width*
  .max-w-#{($i * 50)}px{ 
    max-width: (($i * 50) * 1px) !important;
  }

  // *max height*
  .max-h-#{($i * 50)}px{ 
    max-height: (($i * 50) * 1px) !important;
  }
  
  // *min height*
  .min-h-#{($i * 50)}px{ 
    min-height: (($i * 50) * 1px) !important;
  }
  
  // *min width*
  .min-w-#{($i * 50)}px{ 
    min-width: (($i * 50) * 1px) !important;
  }
  
  @media (min-width: 768px){
    
    .h-md-#{($i * 50)}px{
      height: (($i * 50) * 1px) !important;
    }

    .w-md-#{($i * 50)}px{ 
      width: (($i * 50) * 1px) !important;
    }

  }

}

.border-none{ border: none !important; }

// radius
.radius-none{ border-radius: 0px !important;}
.radius-all{ border-radius: 300px !important;}

@for $i from 15 to 0 {
  
  .radius-#{($i * 3)} {
    border-radius: ($i * 3) * 1px !important;
  }

}


// tools
.img-cover{
  object-fit: cover;
}

.img-scale{
  object-fit: scale-down !important;
}

// shadow filter
.filter-shadow{
  filter: drop-shadow(2px 4px 6px black);
}

// negative margin/position
@for $i from 10 to 0 {
  
  .mnr-#{($i*5)}{
    margin-right: ($i*5) * -1px !important;
  }
  .mnl-#{($i*5)}{
    margin-left: ($i*5) * -1px !important;
  }
  .mnt-#{($i*5)}{
    margin-top: ($i*5) * -1px !important;
  }
  .mnb-#{($i*5)}{
    margin-bottom: ($i*5) * -1px !important;
  }

  .pnr-#{($i*5)}{
    right: ($i*5) * -1px !important;
  }
  .pnl-#{($i*5)}{
    left: ($i*5) * -1px !important;
  }

}


// others 
.float-card{
  padding: 40px;
  position: absolute;
  z-index: 9;
  background: white;
  border-radius: 40px;
  text-align: justify;
  margin-left: -100px;
  margin-top: 100px;
  max-width: 500px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
}

@media (max-width: 860px) {
  
  .float-card{

    position: relative !important;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    max-width: 100%;
    padding-bottom: 0;

    .float-card-img{
      text-align: center;
      position: relative !important;
      right: auto;
      width: 100%;
      top: auto;
    }

  }

  .what-is .max-h-400px{
    max-height: none !important;
  }

  .flower{
    width: 100px;
    height: 100px;
  }

}

.float-btn{
  position: absolute;
  background: black;
  color: white;
  left: 10%;
}

.img-caption{
  
  display: flex;
  position: relative;
  flex-direction: column;

  img{
    height: 100%;
    object-fit: cover;
  }

  .img-caption-content{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }
}

.tag-instagram{
  position: absolute;
  z-index: 9;
  margin-top: 70px;
  margin-left: -100px;
}


.item-product-simple{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  .item-product-favorite{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  .item-product-img img{
    max-width: 100%;
    object-fit: cover;
  }

  .item-product-descripction{
    text-align: center;
    padding: 5px 0px;

    P{ 
      margin:0px;
    }
  }
}

.menu-float{
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0;
  z-index: 999;
  padding: 40px;
  ul{
     margin: 0;
  }
}

.close-form,
#open-menu-mobile,
.close-menu{
  cursor: pointer;
}


.form-float{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  padding: 40px;
}


#filters .dropdown-toggle::after{
  display: none;
}

.custom-checkbox .custom-control-label::before{
  border-radius: 0;
}


.text-overline{ text-decoration: overline; }
.text-through{ text-decoration: line-through !important; }
.text-underline{ text-decoration: underline; }
.text-italic{ font-style: italic; }
.text-hover-link:hover,
.text-link{ text-decoration: underline; font-weight: 600; }

.quantity {
  position: relative;
  display: flex;
  flex-direction: row;

  -ms-flex-pack: center!important;
  justify-content: center !important;
  
  -ms-flex-align: center!important;
  align-items: center!important;
}

.quantity.quantity-left{
  -ms-flex-pack: start!important;
  justify-content: start !important;
  
  -ms-flex-align: start!important;
  align-items: start!important;
}

.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

.quantity input[type=number]{
  -moz-appearance: textfield;
}

.quantity input {
  width: 50px;
  height: 20px;
  line-height: 10px;
  padding: 0;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 0px;
  color: black;
  font-size: 10px;
}

.quantity input:focus {
  outline: 0;
}

.quantity .quantity-down,
.quantity .quantity-up {
  width: 40px;
  height: 20px;
  padding: 0 5px;
  border: solid 1px #707070;
  line-height: 12px;
  font-size: 16px;
  /* border-radius: 16px; */
  text-align: center;
  cursor: pointer;
}

.ws{
  
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #00296d;
  padding: 10px;
  border-radius: 50%;

  img {
    width: 35px;
    filter: invert(1);
  } 
}

// img-under
.img-under{
  
  min-height: 600px;
  display: flex;
  align-content: center;
  align-items: center;

  img {
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: scale-down;
  }
}

input::placeholder{
  color: inherit;
}

.img-bg-over{
  width: 100%;
  max-width: 1200px;
  position: absolute;
  left: 0;
  margin: 0 auto;
  z-index: -1;
  right: 0;
}

input[type="search"]{
  padding-left: 30px;
  background: url(../assets/images/search-gray.svg) no-repeat;
  background-position: 5px 5px;
  background-size: 20px;
}

.line-after-center,
.line-after-left{
  display: flex;
  flex-direction: column;
}

.line-after-center{

  align-items: center;

  &::after{
    content: ' ';
    width: 75px;
    border-bottom: solid 3px setColor("yellow");
    display: block;
    margin-top: 7px;
    margin-bottom: 10px;
  }
}

.line-after-left{
  
  align-items: start;

  &::after{
    content: ' ';
    width: 75px;
    border-bottom: solid 3px setColor("yellow");
    display: block;
    margin-top: 7px;
    margin-bottom: 10px;
  }
}

a.btn-selers{
  border-radius: 50px;
  display: inline-block;
  background: white;
  color: setColor("gray");
  border: solid 1px setColor("gray-o");
  padding: 5px 15px;
  min-width: 120px;
  text-align: center;
  margin: 10px 5px;

  &:hover,
  &.active{
    background: setColor("yellow");
    color: setColor("black");
  }
}

#brands-category {
  .row{
    display: flex;
    justify-content: space-between;

    > div {
      
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center; 

      > div {
        height: 100%;
        background-color: setColor("gray");

        &:hover{
          background-color: setColor("yellow");
          color:  setColor("gray");
        }

        a{
          display: block;

          p{
            margin-top: 5px;
            margin-bottom: 0;
          }
        }
      }

    }
  }
}


#filters-products{
  background-color: #F2F2F2;
  padding: 5px;

  .accordion-item{

    h2 button{
      background-color: setColor("yellow");
      padding: 5px 10px;
      color: setColor("dark");

      &:not(.collapsed) {
        font-weight: bold;
        color: setColor("dark")
      }

      &::after{
        filter: grayscale(1);
      }
    }

    ul li{
      padding: 6px 10px;
      color: setColor("dark");
      border-top: solid 1px setColor("gray-o");
      
      &::after{
        content: '+';
        float: right;
        color: setColor("dark")
      }

    }

  }
}

.items-prod {

  .description1,
  .description2{
    font-size: 12px;
  }

  .out{
    background-color: #BA1622;
    color:white;
    font-weight: 600;
    line-height: 20px;
    padding: 5px 2px;
    width: 70px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
  }

  .special{
    background-color: #3AA635;
    color: white;
    font-weight: 600;
    padding: 5px 15px 5px 5px;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 15px 15px 0;
  }
}

.out-list{
  
  font-size: 10px;
  background: #BA1622;
  color: white;
  text-align: center;
}

.special-list{
  background-color: #3AA635;
  color: white;
  font-weight: 600;
  padding: 2px 15px 2px 5px;
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -30px;
  border-radius: 0 15px 15px 0;
}

.modal-backdrop{
  z-index: 9999;
}