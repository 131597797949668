@import "~@splidejs/splide/dist/css/splide.min.css";

// $source-hover: url(../assets/images/hover.png);

.splide__video__play{
  
  width: 60px;
  height: 60px;
  background-color: rgba(255,255,255, 0.50);

}

button.splide__arrow{

  &.splide__arrow--next[disabled],
  &.splide__arrow.splide__arrow--prev[disabled]{
    display: none;
  }

}


.img-hover-description{
  margin-top: -90px;
  z-index: 99999;
  position: absolute;
  height: 150px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  text-align: center;
  font-size: 14px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  // &::before {
  //   content: ' ';
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 350px;
  //   height: 160px;
  //   z-index: 1;
  //   opacity: 0.65;
  //   background-image: $source-hover !important;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  // }

  div{
    color: black;
    z-index: 99;
    max-height: 150px;
    text-align: center;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    padding-top: 60px;
    width: 180px;

    > h5 {
      font-weight: bold;
    }

    > p {
      font-size: 13px;
      padding: 0 10px;
      text-shadow: 0px 0px 8px rgba(150, 150, 150, 1);
    }
  }
}

a.product-img-hover{
  
  .img-hover-secoundary{
    display: none;
    position: absolute;
    top: 0;
    margin-top: -30px;

  }

  &:hover {

    > p {
      display: none;
    }

    .img-hover-secoundary{
      display: block;
    }

  }
}

.splide__slide .splide__caption{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}