footer{
  ul.nav.flex-column {

    li {
      margin-bottom: 5px;

      > a {
        color: inherit;
      }
    }
    
  }
}