$theme-colors: (
  "primary": #0F89DD,
);

$link-color:#000000;

@media (min-width: 1200px) {
  .container-fluid-xl {
    max-width: 1329px;
  }
}