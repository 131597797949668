*{
  font-family: 'roboto';
}
.ff-hover-roboto:hover,
.ff-roboto{ font-family: 'roboto' !important; }
.ff-hover-roboto-italic:hover,
.ff-roboto-italic{ font-family: 'roboto-italic' !important; }
.ff-hover-roboto-medium:hover,
.ff-roboto-medium{ font-family: 'roboto-medium' !important; }
.ff-hover-roboto-bold:hover,
.ff-roboto-bold{ font-family: 'roboto-bold' !important; }
.ff-hover-roboto-light:hover,
.ff-roboto-light{ font-family: 'roboto-light' !important; }
.ff-hover-roboto-black:hover,
.ff-roboto-black{ font-family: 'roboto-black' !important; }
.ff-hover-roboto-black-italic:hover,
.ff-roboto-black-italic{ font-family: 'roboto-black-italic' !important; }
.ff-font-awesome-5 { font-family: "font-awesome-5" !important; }

.icon-box{
  display: inline-block;
  &::after{
    content: '\f49e';
    font-family: "font-awesome-5"
  }
}

.icon-library{
  display: inline-block;
  &::after{
    content: '\f518';
    font-family: "font-awesome-5"
  }
}

.icon-shipping{
  display: inline-block;
  &::after{
    content: '\f48b';
    font-family: "font-awesome-5"
  }
}